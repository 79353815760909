.proposal,
.shooterinfo,
.request {
  display: flex;
  flex-direction: column-reverse;
  @include up($lg) {
    flex-direction: row;
  }
  margin: 1rem 3rem;

  &__details,
  &__gallery,
  &__feedback,
  &__actionable {
    border-radius: 1px;
    box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.08);
    padding: 2rem;
    flex-grow: 1;
    background: #fff;
    @include up($lg) {
      min-width: calc(50% - 0.5rem);
    }
    span {
      @extend %text-body;
    }
  }

  &__gallery,
  &__feedback {
    margin: 0 3rem;
  }

  &__feedback {
    margin-bottom: -3rem;
  }

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    @extend %heading;
    outline: 0;
    font-size: 2rem;
    padding: 1rem;
    text-transform: uppercase;
    @extend %text-body;
    width: 100%;
    text-align: center;
    user-select: none;
    &-icon {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
      display: inline-block;
      background-size: contain;
      background-image: url('/static/img/checkmark.svg');
    }
    &--small {
      font-size: 1.25rem;
      line-height: 1.25;
    }
    &--won {
      border: solid 5px $brand-green;
      background: $brand-green;
      color: $white;
    }
    &--ok {
      border: solid 5px $brand-green;
      background: $brand-green;
      color: $white;
    }
    &--waiting {
      border: solid 5px $brand-green;
      background: transparent;
      color: $brand-green;
    }
    &--no-offer,
    &--cancelled {
      border: solid 5px $mid-grey;
      background: $mid-grey;
      color: $white;
    }
    &--missing-info {
      border: solid 5px $brand-color;
      background: transparent;
      color: $brand-color;
    }
    &--proposed {
      border: solid 5px $brand-green;
      background: $brand-green;
      color: $white;
    }
    &--not-selected {
      border: solid 5px $brand-color;
      background: $brand-color;
      color: $white;
    }
    &--not-ok {
      border: solid 5px $brand-color;
      background: $brand-color;
      color: $white;
    }
    &--error {
      border: solid 5px $brand-color;
      background: $brand-color;
      color: $white;
    }
  }

  &__details {
    margin-bottom: 0.5rem;
    @include up($lg) {
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }

  &__actionable {
    @include up($lg) {
      margin-right: 0.5rem;
    }
  }

  &__header {
    @include dashboard-header();
  }

  &__nav {
    @include dashboard-nav();
  }

  &__location {
    @extend %heading;
    margin-bottom: 0;
    text-align: center;
    @include down($sm) {
      font-size: 2.5rem;
    }
    &--proposal {
      font-size: 2.5rem;
    }
  }

  &__weddingdate {
    @extend %heading;
    margin-bottom: 0;
    font-size: 3rem;
    @include down($sm) {
      font-size: 2rem;
    }
    &--proposal {
      font-size: 2rem;
    }
  }

  &__date {
    @extend %heading;
    padding-top: 0;
    font-size: 2.5rem;
    text-align: center;
  }

  &__detail {
    &-header {
      border-bottom: solid 1px $light-grey;
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 2rem 2rem;
      margin-bottom: 2rem;
      &-name,
      &-gallery,
      &-feedback {
        @extend %heading;
        font-size: 1.5rem;
        color: $mid-grey;
        margin: 0;
        padding-left: 3rem;
      }
      &--status {
        background-image: url('/static/img/flower.svg');
      }
      &--types {
        background-image: url('/static/img/camera.svg');
      }
      &--name {
        background-image: url('/static/img/camera.svg');
      }
      &--gallery {
        background-image: url('/static/img/photos.svg');
      }
      &--feedback {
        background-image: url('/static/img/seating-guest.svg');
      }
      &--shooter {
        background-image: url('/static/img/camera.svg');
      }
      &--cancelled {
        padding-left: 0;
        border: 0;
        > h2 {
          padding: 0;
        }
      }
      &--accepted {
        background-image: url('/static/img/doorway.svg');
      }
      &--description {
        background-image: url('/static/img/card.svg');
      }
      &--proposal {
        background-image: url('/static/img/wedding-invitation.svg');
      }
      &--email {
        background-image: url('/static/img/wedding-invitation.svg');
      }
      &--created {
        background-image: url('/static/img/ring.svg');
      }
      &--deadline {
        background-image: url('/static/img/heart-shaped-lock.svg');
      }
      &--effective {
        background-image: url('/static/img/wedding-day.svg');
      }
      &--budget {
        background-image: url('/static/img/diamond.svg');
      }
      &--orgcode {
        background-image: url('/static/img/diamond.svg');
      }
      &--rights {
        background-image: url('/static/img/photos.svg');
      }
      &--stats {
        background-image: url('/static/img/wedding-day.svg');
      }
    }
    &-content {
      margin-bottom: 2rem;
      &--types {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .type-with-desc {
          display: none;
          &.wanted {
            display: flex;
          }
          margin: 0 0 1rem 0;
          align-items: center;
        }
      }
    }
  }

  &__typename {
    @extend %text-body;
    text-align: center;
    margin: 0 0 0 2rem;
  }

  &__icon {
    display: block;
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    &--prep {
      background-image: url('/static/img/prep_grey.svg');
      &.wanted {
        background-image: url('/static/img/prep.svg');
      }
    }

    &--portraits {
      background-image: url('/static/img/portraits_grey.svg');
      &.wanted {
        background-image: url('/static/img/portraits.svg');
      }
    }

    &--ceremony {
      background-image: url('/static/img/ceremony_grey.svg');
      &.wanted {
        background-image: url('/static/img/ceremony.svg');
      }
    }

    &--party {
      background-image: url('/static/img/party_grey.svg');
      &.wanted {
        background-image: url('/static/img/party.svg');
      }
    }
  }

  &__created,
  &__budget,
  &__rights {
    padding-left: 3rem;
  }

  &__description {
    pointer-events: none;
    background: $lightest-grey;
    border-radius: 1px;
    padding: 3rem;
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
    margin-bottom: 0.5rem;
    white-space: pre-line;
  }

  &__names {
    text-align: right;
    font-style: italic;
  }

  .generalform {
    margin: 0 auto;
    @include maxwidts-1();
  }

  &__state {
    text-align: center;
    @extend %text-body;
  }

  &__offer {
    color: $brand-color;
    cursor: pointer;
  }

  &__cta {
    @extend %button-inactive;
    width: 100%;
    text-align: center;
    &--won {
      @extend %button-won;
    }
    &--no-offer {
      @extend %button-nonfunctional;
    }
  }

  &__next-info {
    text-align: center;
  }
}

.request {
  &__tip {
    padding-top: 0;
    padding-left: 3rem;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: $mid-grey;
    letter-spacing: 0;
    span {
      font-size: 1.25rem;
    }
  }
}
