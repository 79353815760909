.dashboard {
  background: $lightest-grey;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  &__top {
    height: $nav-width-sm + 2rem;
    @include up($sm) {
      display: none;
    }
  }
  &__bottom {
    display: flex;
  }
  &__left {
    display: none;
    @include up($sm) {
      display: block;
    }
    width: $nav-width;
    min-width: $nav-width;
  }
  &__right {
    flex-grow: 1;
  }
  &__content {
    position: relative;
  }
  &__header {
    height: $nav-width;
    background-color: $true-white;
  }
}

.dashboard-fade {
  &-enter {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s ease;
    transition-delay: 0.2s;
    &-active {
      opacity: 1;
    }
  }
  &-exit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s ease;
    opacity: 1;
    &-active {
      opacity: 0;
    }
  }
}
