@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  /* ... the rest of the rules ... */
  .invoice {
    background: yellow;
    width: 210mm;
    height: 297mm;
    font-size: 30rem;
    border: solid 1px black;
  }
}
