.badge {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  @include up($sm) {
    top: 2rem;
    right: 2rem;
  }
  background: $brand-green;
  color: $white;
  border-radius: 100%;
  font-size: 1rem;
  line-height: 1;
  width: 2rem;
  padding: 0;
  margin: 0;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  @extend %text-body;
  z-index: map-get($zindex, badge);
}
