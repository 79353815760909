.feedback-table,
.proposal-table,
.request-table {
  &__header {
    @include dashboard-header();
    max-width: calc(100vw - 6rem);
  }

  &__nav {
    @include dashboard-nav();
  }

  &__table {
    margin: 1rem 3rem;
    border-collapse: collapse;
    max-width: calc(100vw - 6rem);
  }

  &__head-item {
    align-items: center;
    justify-content: flex-start;
    @extend %heading;
    color: $mid-grey;
    @include up($sm) {
      font-size: 1.5rem;
    }
    font-size: 1.25rem;
    text-align: left;
    outline: none;
    margin-bottom: 0;

    &--sortable {
      padding: 0 4rem 0 6rem;
      cursor: pointer;
      transition: color 400ms ease, background-image 400ms ease;
      background-repeat: no-repeat;
      background-position: 2.5rem center, 90% center;
      background-size: 2rem 2rem;
      &:hover {
        background-position: 2.5rem center, 90% center;
        background-image: url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--createddate {
      display: none;
      @include up($md) {
        display: flex;
      }

      background-image: url('/static/img/ring_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/ring.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/ring.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/ring.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--weddingdate {
      background-image: url('/static/img/wedding-day_grey.svg'),
        url(data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==);
      &:hover {
        background-image: url('/static/img/wedding-day.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/wedding-day.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/wedding-day.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--location {
      background-image: url('/static/img/suitcase_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/suitcase.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/suitcase.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/suitcase.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--recommendation,
    &--types {
      background-image: url('/static/img/camera_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/camera.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/camera.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/camera.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--types {
      display: none;
      @include up($lg) {
        display: flex;
      }
    }

    &--proposed {
      background-image: url('/static/img/flower_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/flower.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/flower.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/flower.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--createdon {
      display: none;
      @include up($lg) {
        display: flex;
      }
      background-image: url('/static/img/dove_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/dove.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/dove.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/dove.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--feedback-given {
      background-image: url('/static/img/card_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/card.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/card.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/card.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--shooter {
      background-image: url('/static/img/camera_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/camera.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/camera.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/camera.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--customer {
      background-image: url('/static/img/portraits_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/portraits.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/portraits.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/portraits.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--rating {
      background-image: url('/static/img/ring_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/ring.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/ring.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/ring.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--feedback,
    &--proposed-amount {
      background-image: url('/static/img/wedding-invitation_grey.svg'),
        url('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      &:hover {
        background-image: url('/static/img/wedding-invitation.svg'),
          url('/static/img/cupid-bow-and-arrow.svg');
        color: inherit;
      }
      &.-sort-asc {
        color: inherit;
        background-image: url('/static/img/wedding-invitation.svg'),
          url('/static/img/heart-with-arrow.svg');
      }
      &.-sort-desc {
        color: inherit;
        background-image: url('/static/img/wedding-invitation.svg'),
          url('/static/img/heart-with-arrow_down.svg');
      }
    }

    &--customer,
    &--feedback-given {
      display: none;
      @include up($xl) {
        display: flex;
      }
    }
  }

  &__data {
    padding: 1rem 1rem 1rem 6rem;
    @extend %text-body;
    margin: 0;
    font-size: 1.5rem;
    line-height: 1;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    display: flex;

    &--created-date {
      display: none;
      @include up($md) {
        display: flex;
      }
    }

    &--createdon {
      display: none;
      @include up($lg) {
        display: flex;
      }
    }

    &--wedding-date {
      @include up($sm) {
      }
    }

    &--location {
    }

    &--types {
      display: none;
      @include up($lg) {
        display: flex;
      }
    }

    &--proposed {
      @extend %heading;
      margin: 0;
      // display: none;
      // @include up($xl) {
      // display: flex;
      // }
      &[value='tarjoa'] {
        color: $brand-color;
      }
    }

    &--customer,
    &--feedback-given {
      display: none;
      @include up($xl) {
        display: flex;
      }
    }
  }

  &__icon {
    display: block;
    width: 2rem;
    height: 2rem;

    &--prep {
      background-image: url('/static/img/prep_grey.svg');
      &.wanted {
        background-image: url('/static/img/prep.svg');
      }
    }

    &--portraits {
      background-image: url('/static/img/portraits_grey.svg');
      &.wanted {
        background-image: url('/static/img/portraits.svg');
      }
    }

    &--ceremony {
      background-image: url('/static/img/ceremony_grey.svg');
      &.wanted {
        background-image: url('/static/img/ceremony.svg');
      }
    }

    &--party {
      background-image: url('/static/img/party_grey.svg');
      &.wanted {
        background-image: url('/static/img/party.svg');
      }
    }
  }

  &--none {
    background: transparent;
    max-width: calc(100% - 6rem);
    margin: 0 auto;
    padding: 1rem 2rem 4rem;
    margin-bottom: 4rem;
    box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.08);
    padding-top: 2rem;
    box-shadow: none;
  }

  &--empty {
    @extend %heading;
    font-size: 2rem;
    text-align: center;
    padding: 2rem 0;
  }
}

.proposal__container .feedback-table--none {
  background: $true-white;
  max-width: calc(100% - 6rem);
  margin: 0 auto;
  padding: 1rem 2rem 4rem;
  margin-bottom: 4rem;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.08);
}

.longdate {
  display: none;
  @include up($lg) {
    display: inline;
  }
}

.shortdate {
  display: inline;
  @include up($lg) {
    display: flex;
    align-items: center;
  }
}

.type-with-desc {
  display: flex;
  align-items: center;
}

.typename {
  // display: none;
}

.rt-table {
  .reactable-filter-input {
    display: block;
    border: 0;
    padding: 0.5rem;
    margin: 0 auto;
    @extend %text-body;
    outline: none;
    &:before {
      content: 'Suodata:';
    }
  }

  .rt {
    // Table row, all rows
    &-tr {
      display: flex;
      transition: background 0.4s ease;
      background: #fff;
      transition: border 300ms ease, transform 300ms ease, box-shadow 300ms ease;
    }

    &-thead {
      margin-bottom: 0.25rem;
    }

    // Table row, body rows
    &-tbody .rt-tr {
      border-bottom: solid 1px $light-grey;
      margin-bottom: 0.25rem;
      border-radius: 1px;

      &:hover {
        cursor: pointer;
        border-bottom: solid 1px $brand-color;
        transform: translateY(-2px) scale(1.005);
        box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.08);
        cursor: pointer;
      }
    }
  }
}

.-loading {
  display: none;
}

.feedback-status,
.proposal-status,
.rfp-status {
  padding: 0.35rem 1rem 0.15rem;
  border-radius: 1rem;
  font-size: 1rem;
  color: $true-white;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.025);
  min-width: 6rem;
  text-align: center;
  &--offered {
    color: $black;
    border: solid 2px $black;
  }

  &--won {
    background-color: $brand-green;
    border: solid 2px $brand-green;
  }

  &--closed,
  &--no,
  &--cancelled {
    color: $mid-grey;
    border: solid 2px $mid-grey;
  }

  &--offer {
    color: $brand-green;
    border: solid 2px $brand-green;
  }

  &--not-selected {
    color: $brand-color;
    border: solid 2px $brand-color;
  }

  &-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    display: inline-block;
    display: none;
    background-size: contain;
    &--offered,
    &--won {
      background-image: url('/static/img/checkmark.svg');
    }
    &--closed {
      background-image: url('/static/img/x_trans.svg');
    }
    &--offer {
      background-image: url('/static/img/checkmark.svg');
    }
    &--not-selected {
      background-image: url('/static/img/checkmark_inv.svg');
    }
  }
}

.feedback-status--won,
.feedback-status--no,
.feedback-status--offered,
.feedback-status--closed,
.proposal-status--won {
  @extend %heading;
  padding: 0.35rem 1rem 0.15rem;
  border-radius: 1rem;
  font-size: 1rem;
  margin: 0;
  line-height: 1;
}

.feedback {
  &__row {
    border-bottom: solid 1px $light-grey;
    margin-bottom: 0.25rem;
    border-radius: 1px;
    background: $true-white;
    padding: 1rem;
  }
  &__content {
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
    background: $lightest-grey;
    padding: 2rem;
    max-width: 768px;
    margin: 0 auto;
  }
  &__feedback {
    @extend %text-body;
    white-space: pre-line;
    margin-bottom: 0;
  }
  &__authors {
    @extend %text-body;
    font-style: italic;
    max-width: 768px;
    margin: 0 auto;
    text-align: right;
  }
}
