.cost {
  &__hero {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $brand-color;
    color: $true-white;
  }

  &__heading {
    @extend %heading;
    max-width: 80%;
  }

  &__subheading {
    @extend %heading;
    font-size: 2rem;
  }

  &__article {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-content {
      width: 512px;
      max-width: 80%;
    }
  }

  &__cost {
    @extend %heading;
    text-align: center;
  }

  &__costs {
    display: flex;
    justify-content: space-around;
  }

  &__version {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__name {
    @extend %heading;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
  }

  &__amount {
    @extend %heading;
    font-size: 4rem;
  }

  &__bottom {
    min-height: 4rem;
    padding: 2rem 0;
    margin-bottom: 2rem;
  }

  &__buttons {
    margin: 0 auto;
    width: 384px;
    max-width: 80%;
  }

  &__cta {
    @extend %button;
    width: 100%;
  }

  &__type {
    margin-bottom: 0.75rem;
  }

  &__counter {
    label {
      text-transform: none;
      font-size: 1.5rem;
      font-style: italic;
      position: relative;
      display: inline-block;
      margin-right: 0;
      padding-left: 3.5rem;
      cursor: pointer;
      transition: color 0.2s ease;
      &:before {
        border-radius: 3px;
      }
    }

    input {
      display: none;
      & + label:before {
        font-size: 2rem;
        line-height: 1.05;
        position: absolute;
        top: 50%;
        left: 0;
        width: 2rem;
        height: 2rem;
        content: '';
        transition: all 0.3s ease;
        transform: translateY(-50%);
        text-align: center;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      }

      &:checked + label {
        &:before {
          content: '\2713';
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
