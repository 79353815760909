.nav {
  position: fixed;
  display: flex;
  z-index: map-get($zindex, nav);
  transition: all 200ms ease;
  height: $nav-width-sm;
  width: 100%;
  @include up($sm) {
    width: $nav-width;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
  }

  &__preload {
    background-image: url('/static/img/key-of-love.svg'),
      url('/static/img/seating-guest.svg'),
      url('/static/img/heart-shaped-lock.svg'), url('/static/img/camera.svg'),
      url('/static/img/dove.svg');
  }
  &--dashboard,
  &--proposals {
    &.visible {
      background-color: #fff;
      box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.16);
    }
  }

  &--confirm {
    &.visible {
      background-color: transparent;
      box-shadow: none;
    }
  }

  a:hover {
    color: $brand-color;
  }

  &__item {
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 4rem 4rem;
    opacity: 0;

    &.visible {
      opacity: 1;
    }

    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &--logo {
      opacity: 1;
    }

    &--admin {
      background-image: url('/static/img/key-of-love_grey.svg');
      &.active {
        background-image: url('/static/img/key-of-love.svg');
      }
    }

    &--rfps,
    &--proposals {
      background-image: url('/static/img/seating-guest_grey.svg');
      &.active {
        background-image: url('/static/img/seating-guest.svg');
      }
    }

    &--shooter {
      background-image: url('/static/img/heart-shaped-lock_grey.svg');
      &.active {
        background-image: url('/static/img/heart-shaped-lock.svg');
      }
    }

    &--login {
      background-image: url('/static/img/key-of-love_grey.svg');
      &:hover {
        background-image: url('/static/img/key-of-love.svg');
      }
    }

    &--logout {
      background-image: url('/static/img/dove_grey.svg');
      &:hover {
        background-image: url('/static/img/dove.svg');
      }
    }

    &--join {
      background-image: url('/static/img/camera_grey.svg');
      &:hover {
        background-image: url('/static/img/camera.svg');
      }
    }

    &-container {
      width: $nav-width-sm;
      height: $nav-width-sm;
      @include up($sm) {
        height: 13rem;
        width: $nav-width;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: solid 3px transparent;
      transition: all 200ms ease;
      z-index: map-get($zindex, nav-item-container);

      &.active {
        color: $brand-color;
        @include down($sm) {
          border-bottom: solid 2px $brand-color;
        }
        @include up($sm) {
          border-right: solid 3px $brand-color;
        }
      }

      &:hover {
        .nav__item-name {
          opacity: 1;
        }
      }

      &--logo.visible {
        background-color: $brand-color;
      }
    }

    &-name {
      display: none;
      color: $mid-grey;
      @include up($sm) {
        display: block;
      }
      @extend %plain-heading;
      transition: opacity 200ms ease;
      position: absolute;
      bottom: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(5rem);
      font-size: 1rem;
      width: $nav-width;
      text-align: center;

      &.active,
      .nav__item-name {
        color: $brand-color;
      }
    }
  }

  &__login-container {
    @include up($sm) {
      height: $nav-width;
    }
    position: absolute;
    transition: opacity 200ms ease, visibility 200ms ease;
    @extend %heading;
    margin: 0;
    padding: 2rem;
    right: 0;
    height: $nav-width-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    z-index: map-get($zindex, nav);
  }

  &__login-link {
    outline: none;
    margin: 0 2rem;
    line-height: 1.5;
    color: $true-white;
    transition: background-image 200ms ease;
    background-repeat: no-repeat;
    background-position: 0% 40%;
    background-size: 1.5rem 1.5rem;
    padding-left: 2.25rem;

    &:visited,
    &:focus,
    &:active,
    &:hover {
      color: $true-white;
    }

    &--proposals {
      background-image: url('/static/img/seating-guest_grey.svg');
      &:hover {
        background-image: url('/static/img/seating-guest.svg');
      }
    }

    &--login {
      background-image: url('/static/img/key-of-love_grey.svg');
      &:hover {
        background-image: url('/static/img/key-of-love.svg');
      }
    }
  }

  &__title-container {
    display: none;
    @include up($sm) {
      display: flex;
    }
    pointer-events: none;
    position: absolute;
    transition: opacity 200ms ease, visibility 200ms ease;
    @extend %heading;
    margin: 0;
    padding: 0;
    left: $nav-width;
    height: $nav-width;
    width: 320px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    background: $brand-color;
    opacity: 0;
    visibility: hidden;

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &--dashboard,
  &--proposals {
    .nav__title-container {
      display: none;
    }
  }

  &__title {
    color: $true-white;
    margin: 0;
    padding: 0;
    letter-spacing: 0.5px;
  }

  &__beta {
    font-style: italic;
  }

  &__logo {
    width: 3.33rem;
    height: 3.33rem;
    cursor: pointer;
    background-image: url('/static/img/logo.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 5rem;
      border: solid 1px $brand-color;
      transition: border 200ms ease;
      &.visible {
        border: solid 1px $true-white;
      }
    }
  }

  &__burger {
    @include burger(
      $width: 2rem,
      $height: 3px,
      $gutter: 4px,
      $border-radius: 1px,
      $color: #fff
    );

    &-container {
      right: 4rem;
      top: 4rem;
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border: solid 1px $brand-color;
    }
  }
}

.route--active {
  font-weight: bold;
}

.hide-this-link-for-now {
  display: none;
}
