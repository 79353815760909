html {
  font-size: 8px;
  @include up($md) {
    font-size: 12px;
  }
}

body {
  text-rendering: optimizeLegibility;
  font-smooth: always;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table,
p {
  @extend %text-body;
}

// Plumber
@include plumber-set-defaults(
  $grid-height: 1rem,
  $baseline: 0.184,
  $font-size: 1.5,
  $line-height: 2,
  $leading-top: 1,
  $leading-bottom: 2
);

%text-body {
  @include plumber($baseline: $body-baseline);
  font-family: $body-font;
  letter-spacing: 0.25px;
}

%heading {
  @include plumber(
    $baseline: $heading-baseline,
    $font-size: 5,
    $line-height: 5,
    $leading-bottom: 2
  );
  font-family: $heading-font;
  text-transform: uppercase;
}

%label {
  @include plumber(
    $baseline: $heading-baseline,
    $font-size: 2,
    $line-height: 2,
    $leading-bottom: 1
  );
  font-family: $heading-font;
  text-transform: uppercase;
}

%plain-heading {
  font-family: $heading-font;
  text-transform: uppercase;
}

%text-shadow {
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.02);
}

%text-hero {
  @extend %text-shadow;
}

%heading-large {
  @extend %heading;
  letter-spacing: 0.1rem;
}

%question-label {
  @extend %heading;
}

%subheading {
  @extend %text-body;
}

%subheading-large {
  @extend %subheading;
}

%user-input {
  @include plumber($baseline: $body-baseline, $font-size: 3);
  font-family: $body-font;
  height: 2.1em;
}

%button {
  display: inline-block;
  @extend %heading;
  @include plumber(
    $baseline: $heading-baseline,
    $font-size: 2,
    $line-height: 2,
    $leading-bottom: 2
  );
  outline: 0;
  border: solid 5px $brand-color;
  background: $brand-color;
  padding: 1rem;
  text-transform: uppercase;
  @extend %text-body;
  color: $white;
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    color: $brand-color;
    background: transparent;
    color: $brand-color;
  }
}

%button-cta {
  @extend %button;
}

%button-shadow {
  @extend %button;
  color: $white;
  border: solid 2px $white;
  background-color: transparent;
  width: 100%;
  text-align: center;
  &:hover {
    border: solid 2px $brand-color;
  }
}

%button-shadow-invert {
  @extend %button-shadow;
  color: $white;
  border: solid 2px $true-white;
  &:hover {
    color: $brand-color;
    border: solid 2px $true-white;
    background: $true-white;
  }
}

%button-inactive {
  @extend %button;
  color: $dark-grey;
  background: $light-grey;
  border: solid 5px $light-grey;
  &:hover {
    border: solid 5px $brand-color;
    cursor: not-allowed;
  }
}

%button-won {
  @extend %button;
  color: $dark-grey;
  background: $brand-green;
  border: solid 5px $brand-green;
  &:hover {
    border: solid 5px $brand-green;
  }
}

%button-nonfunctional {
  @extend %button-inactive;
  &:hover {
    background: $light-grey;
  }
}
