// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin up($bp) {
  @media screen and (min-width: $bp) {
    @content;
  }
}

@mixin down($bp) {
  @media screen and (max-width: $bp) {
    @content;
  }
}

@mixin maxwidths() {
  @include up($xs) {
    max-width: $xs;
  }
  @include up($sm) {
    max-width: $sm;
  }
  @include up($md) {
    max-width: $md;
  }
  @include up($lg) {
    max-width: $lg;
  }
}

@mixin maxwidts-1() {
  @include up($md) {
    max-width: $sm;
  }
  @include up($lg) {
    max-width: $md;
  }
}
/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin show-transition {
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
  }
}

@mixin slice() {
  position: relative;
  &:not(.hero):nth-of-type(even) {
    flex-direction: row-reverse;
  }
  @media screen and (min-width: 920px) {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &__info,
  &__image {
    min-height: 50vh;
    @media screen and (min-width: 920px) {
      width: 50vw;
      height: 100vh;
      padding: 0;
    }
  }
  &__info {
    // position: relative;
    display: flex;
    align-items: center;
    padding: 10rem 0;
    background: $white;
  }
  &__info-content {
    width: calc(100vw / #{$golden-ratio});
    margin: 0 auto;
    @media screen and (min-width: 920px) {
      width: calc(50vw - 50vw / #{$golden-ratio});
    }
  }
  &__image {
    background-image: url('/static/img/rings_02.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
      transition: opacity 1s ease-out, visibility 1s ease-out;
      background: rgba(0, 0, 0, 0.75);
      opacity: 0;
      visibility: hidden;
    }
  }
  &__heading {
    @extend %heading-large;
    @extend %text-hero;
  }
  &__intro {
    @extend %subheading;
  }
  &__cta {
    @extend %button-cta;
  }
}

@mixin editpen() {
  &:after {
    content: '';
    cursor: pointer;
    top: 16px;
    right: 16px;
    width: 3rem;
    height: 3rem;
    background: $brand-green;
    background-image: url('/static/img/pen_white.png');
    background-repeat: no-repeat;
    background-position: 55% center;
    background-size: 1.5rem 1.5rem;
    border-radius: 100%;
    position: absolute;
    @include up($md) {
      opacity: 0;
      visibility: hidden;
    }
    transition: all 0.3s ease;
  }
  &:hover {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}
