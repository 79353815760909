.loader {
  &__container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(
      circle at center,
      #f8f8f8 0,
      #f5f5f5 100%
    );
  }

  #loader-logo {
    height: 128px;
    max-width: 128px;
    transform: translateY(-64px);
    max-height: 128px;
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    animation-direction: alternate;
    opacity: 0;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
  }

  #loader-logo {
    opacity: 1;
  }

  @keyframes spin {
    0% {
      transform: scaleX(1);
      animation-timing-function: cubic-bezier(0.95, 0.05, 0.95, 0.05);
    }
    50% {
      transform: scaleX(0);
      animation-timing-function: cubic-bezier(0.05, 0.95, 0.5, 0.95);
    }
    100% {
      transform: scaleX(-1);
    }
  }
}
