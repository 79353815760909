.hero {
  &__content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: map-get($zindex, hero-info);
  }

  &__top {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    @include up($xl) {
      justify-content: flex-start;
    }
  }

  &__bottom {
    height: 13rem;
    width: calc(100% - 13rem);
    flex-shrink: 0;
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;

    &-right {
      width: 50%;
      background-color: rgba(255, 255, 255, 0.05);
    }

    &-left {
      width: 50%;
      background-color: transparent;
    }
  }
  &__info {
    &-container {
      @include up($xl) {
        margin-left: 192px;
      }
    }

    &-content {
      width: calc(100vw / #{$golden-ratio});
      max-width: 384px;
      margin: 0 auto;
      z-index: map-get($zindex, hero-info);
    }
  }

  &__heading {
    @extend %heading-large;
    @extend %text-hero;
    color: $true-white;
    &--bottom {
      font-size: 1rem;
      margin-left: 3rem;
    }
  }

  &__intro {
    @extend %subheading;
    color: $true-white;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__cta {
    @extend %button-cta;
    text-align: center;
  }

  &__more {
    @extend %button-shadow;
  }

  &__deco {
    background-image: url('/static/img/rings_01.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    height: 0.6675 * 65vw;
    position: absolute;
    right: 0;
    top: 50vh;
    transform: translateY(-50%);
    width: 65vw;
    z-index: map-get($zindex, deco);
    @include up($xl) {
      display: block;
    }
  }

  &__image {
    min-height: 100vh;
    background-image: url('/static/img/hero_bg.jpg');
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      transition: opacity 400ms ease;
      opacity: 0.25;
      pointer-events: none;
    }
    &--form-open::after {
      opacity: 1;
    }
  }

  &--signup {
    .hero {
      &__deco {
        background-image: url('/static/img/camera.png');
        @include up($lg) {
          transform: translateX(-15%) translateY(-50%);
        }

        &.fade-in-out-2000-appear {
          transform: translateX(-15%) translateY(-2.5%);
          @include up($lg) {
            transform: translateX(-15%) translateY(-52.5%);
          }

          &.fade-in-out-2000-appear-active {
            transform: translateX(-15%) translateY(0);
            @include up($lg) {
              transform: translateX(-15%) translateY(-50%);
            }
          }
        }
      }

      &__image {
        background-image: url('/static/img/shooter_hero_bg.jpg');
        background-size: cover;
      }
    }
  }
}
