.proposal-questions {
  display: flex;
  flex-direction: column;
  &__button {
    @extend %button-shadow;
    color: $mid-grey;
    border: solid 2px $mid-grey;
  }

  &__question-text {
    @extend %text-body;
    white-space: pre-line;
    margin: 0;
  }

  &__question,
  &__answer {
    background: $lightest-grey;
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
    padding: 2rem;
    width: 80%;
    max-width: 512px;
  }

  &__answer {
    align-self: flex-end;
  }

  &__answerer,
  &__askers {
    font-style: italic;
    color: $mid-grey;
    font-size: 1rem;
  }

  &__answerer {
    text-align: right;
  }
}
