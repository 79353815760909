.forgot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: radial-gradient(circle at center, #f8f8f8 0, #f5f5f5 100%);

  &__content {
    margin-top: -6rem;
  }

  &__heading {
    @extend %heading;
    color: $mid-grey;
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }

  &__forgot {
    margin-top: 4rem;
    @extend %text-body;
    position: absolute;
    color: $black;
    transition: color 400ms ease;
    &:hover {
      color: $brand-color;
      cursor: pointer;
      outline: none;
    }
  }
}
