.slice {
  @include slice();
  @for $i from 2 through 7 {
    &:nth-child(#{$i}) .slice__image {
      background-image: url('/static/img/rings_0#{$i}.jpg');
    }
  }
  &-2 {
    background: $brand-color;
    color: $white;
    height: 50vh;
    .slice__info {
      height: 50vh;
      background: $brand-color;
      padding: 2rem 0;
    }
  }
}
