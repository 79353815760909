.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__privacy,
  &__text {
    @extend %heading;
    font-size: 1rem;
    color: $medium-grey;
    margin: 0 1rem;
  }

  &__c {
    @extend %text-body;
    color: $medium-grey;
    font-size: 1rem;
    margin: 0;
    margin-top: 0.5rem;
  }

  &__privacy {
    outline: none;
    &:hover {
      color: $brand-color;
      cursor: pointer;
    }
  }
}
