@mixin dashboard-header() {
  margin: 0 3rem;
  display: flex;
  align-items: flex-end;
  border-bottom: solid 3px $light-grey;
  // height: $nav-width-sm;
  @include up($sm) {
    height: $nav-width;
    padding: 0 4rem;
  }
  &--proposal {
    padding: 0;
  }
}

@mixin dashboard-nav() {
  display: flex;
  &-item {
    @extend %heading;
    color: $mid-grey;
    font-size: 1.5rem;
    margin: 0 0 -3px;
    padding: 0 2.5rem;
    border-bottom: solid 3px $light-grey;
    transition: all 300ms ease;
    &:hover {
      cursor: pointer;
      color: inherit;
    }
    &:focus {
      outline: none;
    }
    &.active {
      border-bottom: solid 3px $brand-color;
      color: inherit;
    }
  }
}
