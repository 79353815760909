.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url('/static/img/rings_02.jpg');
  background-size: cover;
  background-position: center center;
}
