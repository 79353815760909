.singleshooter {
  width: 100%;
  background: $lightest-grey;

  &__container {
    background: $true-white;
    padding: 4rem;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.025);
    margin: 0 auto;
    width: 768px;
    max-width: 80%;
  }

  &__bottom,
  &__top {
    min-height: 4rem;
    padding: 2rem 0;
    background: $lightest-grey;
  }

  &__img img {
    max-width: 100%;
  }

  &__name {
    @extend %heading;
    font-size: 2rem;
  }

  &__buttons {
    margin: 0 auto;
    width: 384px;
    max-width: 80%;
  }

  &__cta {
    @extend %button;
    width: 100%;
  }

  &__back {
    @extend %button-shadow;
    color: $black;
    border: solid 2px $black;
  }
}
