.proposal {
  &__p {
    &--amount {
      font-size: 4rem;
    }
    &--description {
      white-space: pre-line;
    }
  }

  &__cancelled {
    font-family: $body-font;
    margin-top: 2rem;
    font-size: 2.5rem;
    color: $dark-grey;
  }

  &-info {
    margin: 2rem 3rem;
    background-color: $light-grey;
    padding: 4rem;
    display: flex;
    justify-content: center;
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);

    &__button {
      margin-top: 4rem;
      @extend %button-shadow;
      color: $dark-grey;
      border: solid 2px $dark-grey;
    }

    &__container {
      max-width: 512px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      margin-bottom: 2rem;
      border: solid 2px $brand-green;
      color: $brand-green;
      font-weight: bold;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
      font-style: italic;
      box-shadow: inset 0 10px 10px rgba(255, 255, 255, 0.025);
    }

    &__content {
      text-align: center;
      @extend %text-body;
      margin: 0;
    }
  }

  &__name {
    margin-top: 0.5rem;
    font-size: 2rem;
    font-style: italic;
    text-align: center;
  }

  &__img {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }

  &__link {
    &:hover {
      color: $brand-color;
    }
  }
}
