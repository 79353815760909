.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  vertical-align: top;

  &__heading {
    @extend %heading;
    font-size: 1.5rem;
    text-align: left;
    margin: 0;
  }

  &__field {
    resize: none;
    position: relative;
    display: block;
    padding: 2rem;
    width: 100%;
    border: none;
    border-radius: 1px;
    background-color: $lightest-grey;
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
    color: $text-color;
    font-weight: 400;
    -webkit-appearance: none; /* for box shadows to show on iOS */
    &:focus {
      outline: none;
    }

    &--wpgform {
      width: 100%;
      padding: 2rem;
      background: $lightest-grey;
      box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
      border: 1px solid $light-grey;
      color: $text-color;
      transition: all 0.25s;
    }

    &--disabled {
      cursor: not-allowed;
      background-color: $medium-grey;
    }
  }

  &__label {
    display: inline-block;
    float: right;
    padding: 2rem;
    color: #696969;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    user-select: none;

    &-content {
      position: relative;
      display: block;
      padding: 2rem;
      width: 100%;

      &--wpgform {
        padding: 0 2rem;
        font-weight: 400;
        color: $text-color;

        &::after {
          content: attr(data-content);
          position: absolute;
          top: -300%;
          left: 2rem;
          color: $brand-color;
          font-weight: 800;
        }
      }
    }

    &--wpgform {
      width: 100%;
      position: absolute;
      top: 3rem;
      text-align: left;
      overflow: hidden;
      padding: 0;
      pointer-events: none;
      transform: translate3d(0, 3rem, 0);
    }
  }

  &--wpgform {
    padding-top: 0;
    margin-bottom: 2rem;
  }

  &__button {
    @extend %button-cta;
    border-radius: 1px;
    text-align: center;
    transition: all 300ms ease;
    width: 100%;
    &[disabled] {
      color: $light-grey;
      border-color: $light-grey;
      background-color: transparent;
      cursor: not-allowed;
    }
    &:focus {
      color: $brand-color;
      background-color: transparent;
    }
    &--affirm {
      color: $brand-green;
      border-color: $brand-green;
      background-color: transparent;
      &:hover {
        color: $white;
        background-color: $brand-green;
      }
    }
  }

  &__fieldset {
    position: relative;
    text-align: left;
  }
}

.input__field--wpgform:focus,
.input--filled .input__field--wpgform {
  border-color: $brand-green;
}

.input--invalid .input__field--wpgform {
  border-color: $brand-color;
}

.input__field--wpgform:focus + .input__label--wpgform,
.input--filled .input__label--wpgform {
  animation: anim-wpgform-1 0.25s forwards;
}

.input__field--wpgform:focus
  + .input__label--wpgform
  .input__label-content--wpgform,
.input--filled .input__label-content--wpgform {
  animation: anim-wpgform-2 0.25s forwards ease-in;
}

@keyframes anim-wpgform-1 {
  0%,
  70% {
    transform: translate3d(0, 3rem, 0);
  }
  71%,
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes anim-wpgform-2 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  70%,
  71% {
    transform: translate3d(0, 125%, 0);
    opacity: 0;
    animation-timing-function: ease-out;
  }
  100% {
    color: transparent;
    transform: translate3d(0, 300%, 0);
  }
}

.radio {
  &__container {
    display: block;
    position: relative;
    padding-left: 3rem;
    margin-bottom: 2rem;
    cursor: pointer;
    user-select: none;
    text-align: left;

    /* Hide the browser's default radio button */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  /* Create a custom radio button */
  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: #eee;
    border-radius: 50%;
    transition: all 300ms ease;
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
  }

  &__container:hover input:not(.input__field--disabled) ~ &__checkmark {
    background-color: $lightest-grey;
  }

  &__container input:checked ~ &__checkmark {
    border: solid 1px $brand-green;
  }

  &__container input:focus ~ &__checkmark {
    border: solid 1px $brand-color;
  }

  &__container input:focus ~ &__checkmark::after {
    background-color: $brand-color;
  }

  &__container input.input__field--disabled ~ &__checkmark {
    cursor: not-allowed;
    background-color: $medium-grey;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &__checkmark:after {
    content: '';
    position: absolute;
  }

  /* Show the indicator (dot/circle) when checked */
  &__container input:checked ~ &__checkmark:after {
    opacity: 1;
  }

  /* Style the indicator (dot/circle) */
  &__container &__checkmark:after {
    opacity: 0;
    transition: opacity 200ms ease;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    background: $black;
  }
}

.checkbox {
  &__container {
    display: block;
    position: relative;
    padding-left: 3rem;
    margin-bottom: 2rem;
    cursor: pointer;
    text-align: left;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  &__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: $lightest-grey;
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
  }

  /* On mouse-over, add a grey background color */
  &__container:hover input:not(.input__field--disabled) ~ &__checkmark {
    background-color: $lightest-grey;
  }

  /* When the checkbox is checked, add a blue background */
  &__container input:checked ~ &__checkmark {
    border: solid 1px $brand-green;
  }

  &__container input:focus ~ &__checkmark {
    border: solid 1px $brand-color;
  }

  &__container input:focus ~ &__checkmark::after {
    border-color: $brand-color;
  }

  &__container input.input__field--disabled ~ &__checkmark {
    cursor: not-allowed;
    background-color: $medium-grey;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &__checkmark:after {
    content: '';
    position: absolute;
  }

  /* Show the checkmark when checked */
  &__container input:checked ~ &__checkmark:after {
    opacity: 1;
  }

  /* Style the checkmark/indicator */
  &__container &__checkmark:after {
    opacity: 0;
    transition: all 200ms ease;
    left: 32%;
    top: 14%;
    width: 35%;
    height: 65%;
    transform: translateX(-50%) translateY(-50%);
    border: solid $black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
