.question-label-cont {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
  height: $rfp-label-height + 5rem;
  @include min-screen($md) {
    height: $rfp-label-height;
  }
}

.question-label {
  display: block;
  color: $white;
  &--forgot,
  &--login {
    color: $black;
  }
}

.question-label-enter {
  position: absolute;
  opacity: 0.95;
  @include min-screen($md) {
    transform: translateY(-$rfp-label-height -2rem);
  }
  transform: translateY(-$rfp-label-height + 3rem);
  &.question-label-enter-active {
    transition: all 0.4s;
    transform: translateY(-2rem);
    opacity: 1;
  }
}

.question-label-exit {
  opacity: 1;
  transform: translateY(0);
  &.question-label-exit-active {
    transition: all 0.4s;
    opacity: 0.95;
    transform: translateY($rfp-label-height + 2rem);
    @include min-screen($md) {
      transform: translateY($rfp-label-height);
    }
  }
}
