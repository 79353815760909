.Table {
  &__itemCount {
    font-size: 1.25rem;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    @extend %heading;
  }

  &__pageButton {
    font-size: 1.25rem;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-transform: uppercase;
    transition: color 400ms ease;

    &:disabled {
      cursor: not-allowed;
      color: $mid-grey;
    }

    &--active {
      color: $brand-color;
      font-weight: bold;
    }
  }
}
