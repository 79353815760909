.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: map-get($zindex, modal);

  &__container {
    padding: 4rem;
    overflow: scroll;
    background-color: $true-white;
    position: relative;
    width: calc(100% - 4rem);
    height: calc(100% - 4rem);
    box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
    z-index: map-get($zindex, modal-container);
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &--confirm {
      align-items: center;
    }
  }

  &__content {
    max-width: 768px;
    display: flex;
    flex-direction: column;
    &--confirm {
      // padding: 8rem;
      // width: 768px;
      // max-width: 80vw;
      // height: auto;
    }
  }

  &__heading {
    @extend %heading;
    font-size: 1.75rem;
    margin-bottom: 0;
    line-height: 1.25;
  }

  &__title {
    @extend %heading;
  }

  &__text {
    @extend %text-body;
    display: flex;
    flex-direction: column;
    margin: 0;
    &-container {
      padding: 2rem;
      background-color: $lightest-grey;
      box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.025);
    }
  }

  &__close {
    @extend %button;
    width: 12rem;
    text-align: center;
    align-self: center;
    margin-top: 2rem;
    &--confirm {
      width: 24rem;
      @media screen and (min-width: 480px) {
        max-width: 45%;
      }
    }
    &--cancel {
      background-color: transparent;
      color: $mid-grey;
      border-color: $mid-grey;
      @media screen and (min-width: 480px) {
        max-width: 45%;
      }
      &:hover {
        border-color: $brand-color;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  p {
    line-height: 1.5;
  }

  &__img {
    align-self: center;
    margin-bottom: 2rem;
    &-links {
      align-self: center;
      margin: 0 0 4rem;
      padding: 0;
      display: flex;
    }
    &-link {
      list-style-type: none;
      margin: 0 1rem;
      font-size: 1rem;
    }
    &-title {
      align-self: center;
      font-style: italic;
      font-size: 1rem;
    }
  }
}
