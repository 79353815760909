.gallery {
  display: block;
  margin: 1rem 3rem;

  &-image {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .gallery-image__overlay {
        opacity: 1;
      }
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      opacity: 1;
      transition: opacity 400ms ease;
      outline: none;
      @include up($md) {
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }

      &--change {
        top: 0;

        @include down($md) {
          .gallery-image__button {
            color: $true-white;
            cursor: pointer;
          }
        }

        &:hover {
          .gallery-image__button {
            color: $true-white;
            cursor: pointer;
          }
        }
      }

      &--delete {
        bottom: 0;

        @include down($md) {
          .gallery-image__button {
            color: $brand-color;
            cursor: pointer;
          }
        }
        &:hover {
          .gallery-image__button {
            color: $brand-color;
            cursor: pointer;
          }
        }
      }

      &--saving {
        background-color: rgba(0, 0, 0, 0.75);
        opacity: 1;
        cursor: not-allowed;

        &:hover {
          .gallery-image__button {
            cursor: not-allowed;
          }
        }
      }
    }

    &__button {
      @extend %heading;
      margin: 0;
      font-size: 2rem;
      transition: color 400ms ease, opacity 400ms ease;
      background: transparent;
      border: none;
      opacity: 1;

      &:focus {
        outline: none;
      }

      &--saving {
        opacity: 0;
        cursor: not-allowed;
      }
    }

    &__add {
      &-cont {
        outline: none;
      }

      &-button {
        @extend %button;

        &--disabled {
          @extend %button-inactive;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.blur-up {
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
  filter: blur(0);
}
