fieldset {
  margin: 0;
  padding: 0.75rem 0;
  border: none;

  label.checkbox-label,
  label.radio-label {
    text-transform: none;
    font-size: 1.5rem;
    font-style: italic;
    position: relative;
    display: inline-block;
    margin-right: 0;
    padding-left: 3.5rem;
    cursor: pointer;
    transition: color 0.2s ease;
    color: rgba(255, 255, 255, 0.55);

    &:before {
      border-radius: 3px;
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    display: none;
    & + label:before {
      font-size: 2rem;
      line-height: 1.05;
      position: absolute;
      top: 50%;
      left: 0;
      width: 2rem;
      height: 2rem;
      content: '';
      transition: all 0.3s ease;
      transform: translateY(-50%);
      text-align: center;
      color: rgba(255, 255, 255, 0.05);
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    }

    &:checked + label {
      color: #fff;

      &:before {
        content: '\2713';
        color: rgba(255, 255, 255, 0.95);
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  input[type='radio'] {
    & + label:before {
      border-radius: 50%;
    }
  }
}
