// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: $text-color;
  &.noscroll {
    overflow: hidden;
  }
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
  }
}

$fade-durations: 500 1000 1500 2000 2500 3000;

@each $t in $fade-durations {
  .fade-in-out-#{$t} {
    opacity: 0.01;
  }

  .fade-in-out-#{$t}.fade-in-out-#{$t}-active {
    opacity: 1;
    transition: all #{$t}ms ease-out;
  }

  .fade-in-out-#{$t}-exit {
    opacity: 1;
  }

  .fade-in-out-#{$t}-exit.fade-in-out-#{$t}-exit-active {
    opacity: 0.01;
    transition: all #{$t}ms ease-out;
  }

  .fade-in-out-#{$t}-enter {
    opacity: 0.01;
  }

  .fade-in-out-#{$t}-enter.fade-in-out-#{$t}-enter-active {
    opacity: 1;
    transition: all #{$t}ms ease-out;
  }
}

.default-fade-enter {
  opacity: 0.01;
  &.default-fade-enter-active {
    transition: all 0.4s ease;
    opacity: 1;
  }
}

.default-fade-exit {
  opacity: 1;
  &.default-fade-exit-active {
    transition: all 0.4s ease;
    opacity: 0.01;
  }
}
