.wpg-form {
  display: flex;
  max-width: 90vw;
  width: 1024px;
  margin: 0 auto;
  margin-top: -12rem;
  justify-content: center;
  position: relative;

  label {
    @extend %question-label;
    max-width: 75%;

    .question-mark {
      color: $brand-color;
    }
  }

  &__inner {
    width: 100%;
    position: relative;
    &.hide {
      visibility: hidden;
      transition: opacity 0.3s, visibility 0s 0.3s;
      opacity: 0;
    }
  }

  .questions fieldset {
    transition: visibility 0.2s ease, opacity 0.2s ease, height 0.3s ease;
    visibility: visible;
    opacity: 1;
    border: none;
    background-color: rgba(0, 0, 0, 0.1);

    label {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.1s ease, opacity 0.1s ease, height 0.3s ease,
        color 300ms ease;
      @extend %subheading;
      margin: 1rem;
    }

    &.questions-4 {
      height: 21rem;
    }

    &.questions-7 {
      height: 36.75rem;
    }
  }

  &.question-answered .questions fieldset {
    height: $rfp-input-height;
    label {
      visibility: hidden;
      opacity: 0;
    }
  }

  .questions.textarea {
    transition: all 0.3s ease;
    &:before {
      height: $rfp-textarea-height;
      transition: all 0.3s ease;
    }
  }

  &.question-answered .questions input,
  &.question-answered .questions textarea {
    color: rgba(0, 0, 0, 0);
  }

  &.question-answered .questions textarea {
    height: $rfp-input-height;
    &:before {
      height: $rfp-input-height;
    }
  }

  &.question-answered .questions.textarea:before {
    height: $rfp-input-height;
  }

  /* Question list style */
  .questions {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-transition: height 0.4s;
    transition: height 0.4s;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      content: '';
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:not(.fieldset):not(.textarea):before {
      height: 7rem;
    }
  }
  .progress {
    position: relative;
    width: 0;
    height: 0.5rem;
    transition: width 0.4s ease-in-out;
    background: rgba(255, 255, 255, 0.75);

    &--forgot,
    &--login {
      background: $brand-color;
    }
  }

  .progress-bg {
    position: absolute;
    width: 100%;
    height: 0.5rem;
    background: rgba(0, 0, 0, 0.25);
  }

  .number {
    @extend %heading;
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.5rem;
    font-weight: 700;
    position: absolute;
    right: 0;
    overflow: hidden;
    width: 3em;
    margin: 0;
    padding: 0;
    line-height: 2;

    &--forgot,
    &--login {
      color: $black;
    }

    &::after {
      position: absolute;
      left: 50%;
      content: '/';
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      opacity: 0.4;
    }

    span {
      float: right;
      width: 40%;
      text-align: center;
    }

    .number-current {
      position: absolute;
      float: left;
      color: $brand-color;
      &.number-enter {
        transform: translateY(-100%);
      }
      &.number-enter-active {
        transition: all 0.4s ease;
        transform: translateY(0);
      }
      &.number-exit {
        transform: translateY(0);
      }
      &.number-exit-active {
        transition: all 0.4s ease;
        transform: translateY(100%);
      }
    }

    .number-next {
      position: absolute;
      left: 0;
    }
  }

  .final-message {
    @extend %heading;
    position: absolute;
    visibility: hidden;
    transition: opacity 0.4s;
    opacity: 0;
    color: rgba(255, 255, 255, 0.95);
    width: 100%;
    text-align: center;

    &.show {
      visibility: visible;
      white-space: pre-line;
      transition-delay: 0.5s;
      opacity: 1;
    }
  }

  .error-message {
    position: absolute;
    transition: opacity 0.4s;
    @extend %subheading;
    font-size: 1.5rem;
    padding: 0.4em 3.5em 0 0;
    color: rgba(255, 255, 255, 0.95);

    &--forgot,
    &--login {
      color: $black;
    }
  }
}

.questions .item {
  position: relative;
  z-index: map-get($zindex, form);
  visibility: visible;
  height: 0;
  -webkit-transition: visibility 0s 0.4s, height 0s 0.4s;
  transition: visibility 0s 0.4s, height 0s 0.4s;
}

.questions .item {
  visibility: visible;
  height: auto;
  -webkit-transition: none;
  transition: none;
}

.questions input,
.questions select,
.questions textarea {
  @extend %user-input;
  display: block;
  width: calc(100% - 2em);
  margin: 0 0 0 0;
  padding: 0.2em 1em 0.5em 0.7em;
  transition: all 0.3s;
  overflow: hidden;
  // opacity: 0;
  color: rgba(255, 255, 255, 0.9);
  border: none;
  background: transparent;
  line-height: normal; // Safari fix

  &::placeholder {
    color: rgba(255, 255, 255, 0.25);
  }
  &.forgot,
  &.login {
    color: $black;
    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
.questions textarea {
  height: $rfp-textarea-height;
  font-size: 1.5rem;
  margin-top: 0.075em; // bugfixing
  padding: 2em 3.5em 2em 2em; // bugfixing
  resize: none;
  overflow-y: scroll;
  transition: all 0.3s ease;
}

.questions input:focus,
.questions textarea:focus,
.wpg-form button:focus {
  outline: none;
}

/* Next question button */
.next {
  position: absolute;
  z-index: map-get($zindex, next);
  right: 1rem;
  bottom: 1.5rem; /* padding-bottom of form plus progress bar height */
  display: block;
  width: 4rem;
  height: 4rem;
  padding: 0;
  cursor: pointer;
  text-align: center;
  // -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  // transition: transform 0.3s, opacity 0.3s;
  // -webkit-transform: translateX(-20%);
  // transform: translateX(-20%);
  pointer-events: none;
  color: rgba(255, 255, 255, 0.4);
  border: none;
  background: none;
  transition: color 0.2s ease;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }

  &::after {
    background: url('/static/img/arrow-circle-right.svg');
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    pointer-events: auto;

    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &.basic-enter {
    transform: translateX(-20%);
    opacity: 0.01;
  }

  &.basic-enter.basic-enter-active {
    transition: all 300ms ease-in;
    transform: translateX(0);
    opacity: 1;
  }

  &.basic-exit {
    opacity: 1;
  }

  &.basic-exit.basic-exit-active {
    transition: all 300ms ease-in;
    opacity: 0.01;
  }
}

.form-result {
  color: $true-white;
  text-align: center;
  z-index: map-get($zindex, form);
  max-width: 80vw;
  &__heading {
    @extend %heading;
  }
  &__info {
    @extend %text-body;
  }
  &--forgot,
  &--login {
    color: $black;
  }
}

/* Remove IE clear cross */
input[type='text']::-ms-clear {
  display: none;
}

/* Adjust form for smaller screens */
@media screen and (max-width: 44.75em) {
  .wpg-form {
    font-size: 1.5em;
  }
}

// TODO hmm?
@media screen and (max-width: 33.5625em) {
  font-size: 1.2em;
}
