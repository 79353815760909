// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$body-font: 'vollkorn', 'georgia', serif;
$body-baseline: 0.184;

/// Code (monospace) font family
/// @type List
$heading-font: 'Unica One', 'helvetica', 'arial', 'sans-serif';
$heading-baseline: 0.174;

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgba(238, 83, 57, 1) !default;

// Palette:
// 8FB9A8   FEFAD4   FCD0BA   F1828D   765D69

/// Lightest grey
/// @type Color
$lightest-grey: rgb(249, 249, 249) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$medium-grey: rgb(192, 192, 192) !default;

/// Mid grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

// $white: #FFF;
// $white: #fdf7f7;
$white: #fdf7f7;
$true-white: #fff;
$brand-green: #4ab19d;
$black: rgb(20, 20, 20);
$true-black: #000;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;
$nav-width-sm: 8rem;
$nav-width: 13rem;
$dashboard-max-width: 768px;

$golden-ratio: 1.618034;

$rfp-label-height: 18rem;
$rfp-input-height: 6rem;
$rfp-textarea-height: 16rem;
/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$xs: 324px;
$sm: 512px;
$md: 768px;
$lg: 920px;
$xl: 1440px;

// z-indices
$zindex: (
  deco: 600,
  hero-info: 700,
  form: 800,
  next: 850,
  nav-item-container: 900,
  nav: 1000,
  badge: 1050,
  modal-container: 1100,
  modal: 1200,
  loadingBar: 2000,
);
