.loading {
  position: fixed;
  height: 5px;
  background-color: $brand-color;
  z-index: map-get($zindex, loadingBar);
}

.desktop {
  display: none;
  @media screen and (min-width: 512px) {
    display: inline;
  }
}
