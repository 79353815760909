.shooters-list {
  background: $lightest-grey;

  a {
    color: inherit;
  }

  &__preload {
    background-image: url('/static/img/doorway.svg'),
      url('/static/img/photos.svg'), url('/static/img/flower.svg');
  }

  &__hero {
    width: 100%;
    padding-top: 33%;
    background-image: url('/static/img/rings_05_169.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__h1 {
    @extend %heading;
    margin: 0;
  }

  &__container {
    margin: 0 auto;
    max-width: 80%;
    width: 768px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__content {
    width: 100%;
  }

  &__shooter {
    cursor: pointer;
    &:hover {
      .shooters-list {
        &__item {
          transform: translateY(-3px);
        }
        &__explanation {
          opacity: 1;
        }
        &__name {
          color: $brand-color;
        }
        &__icon {
          &--www {
            background-image: url('/static/img/doorway.svg');
          }
          &--gallerycount {
            background-image: url('/static/img/photos.svg');
          }
        }
        &__feedback-content {
          // transform: translateY(-3px);
          svg path {
            fill: $brand-color !important;
          }
        }
        &__img-container img {
          transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }
  }

  &__img-container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;

    img {
      transition: all 200ms ease;
      width: 250px;
      max-width: 100%;
      height: auto;
    }
    .shooters-list__img-placeholder {
      width: 75px;
      height: 75px;
    }
  }

  &__img-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__item {
    @media screen and (min-width: 512px) {
      padding: 2rem;
      display: flex;
    }
    padding: 4rem 2rem 2rem 2rem;
    background: $true-white;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.025);
    margin-top: 5rem;
    transform: translateY(0);
    transition: transform 200ms ease;
  }

  &__info {
    @media screen and (min-width: 512px) {
      display: flex;
      padding: 0 0 0 2rem;
      justify-content: space-between;
    }
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 2rem;

    &-left {
      display: flex;
      @media screen and (min-width: 512px) {
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
      }
      justify-content: center;
    }

    &-right {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      transform: scale(0.75);
      @media screen and (min-width: 512px) {
        flex-direction: column;
        margin-top: 0;
        align-items: flex-end;
        transform: scale(1);
      }
    }
  }

  &__flourish {
    display: inline-block;
    height: 8rem;
    opacity: 0.5;
    width: 100%;
    background-image: url('/static/img/flower_grey.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 2rem 2rem;
  }

  &__name {
    text-align: center;
    @media screen and (min-width: 512px) {
      text-align: left;
    }
    @extend %plain-heading;
    font-size: 2rem;
    max-width: 80%;
  }

  &__detail {
    display: flex;
  }

  &__explanation {
    display: none;
    @media screen and (min-width: 512px) {
      display: flex;
    }
    @extend %text-body;
    font-size: 1.25rem;
    color: $dark-grey;
    margin-bottom: 0;
    align-items: center;
    opacity: 0;
    transition: all 200ms ease;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    margin-top: 0.5rem;
    margin-left: 1rem;
    transition: all 200ms ease;

    &--gallerycount {
      background-image: url('/static/img/photos_grey.svg');
    }

    &--www {
      background-image: url('/static/img/doorway_grey.svg');
    }
  }

  &__feedback {
    &-content {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.5rem;
      margin-left: 1rem;
      svg path {
        fill: $medium-grey !important;
      }
    }
  }

  &__bottom {
    height: 5rem;
  }
}
