.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: radial-gradient(circle at center, #f8f8f8 0, #f5f5f5 100%);

  &__message {
    @extend %heading;
    color: $mid-grey;
    font-size: 3rem;
    margin-bottom: 3rem;
  }
}
