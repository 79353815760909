// See _Request.scss
.shooterinfo {
  &__detail-content {
    display: flex;
    flex-wrap: wrap;
  }

  &__section {
    margin: 1rem;
    flex-grow: 1;
    min-width: calc(50% - 2rem);
  }

  &__tip {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: $mid-grey;
    letter-spacing: 0;
    span {
      font-size: 1.25rem;
    }
  }

  &__stats {
    display: flex;
    flex-wrap: wrap;
    background: $true-white;

    &--feedback {
      width: 100%;
      max-width: 100%;
      justify-content: space-between;
    }
  }

  &__stat {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    flex-grow: 1;
    min-width: calc(50% - 2rem);
    padding: 1rem;
    border: solid 1px $medium-grey;
    margin: 1rem;

    &--feedback {
      min-width: calc(33.33% - 2rem);
    }

    &-label {
      @extend %text-body;
      font-size: 1.25rem;
      font-style: normal;
      margin-bottom: 0.5rem;
      color: $dark-grey;
      letter-spacing: 0;
    }

    &-number {
      position: relative;
      @extend %heading;
      margin-bottom: 2rem;
      font-size: 2.5rem;
      color: $black;
      &::after {
        content: '';
        position: absolute;
        bottom: -1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 0.25rem;
        background: $brand-color;
      }
    }
  }

  .input__button {
    margin-top: 2rem;
  }
}

.dropzone:focus {
  outline: none;
}

.profile-image {
  max-width: 100%;
  &__input {
    &:focus {
      outline: none;
    }
  }

  &__container {
    overflow: hidden;
    position: relative;
    max-width: 250px;
    height: 250px;
    margin: 2rem auto;
    &:hover {
      .profile-image__overlay {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  &__overlay {
    position: absolute;
    width: 250px;
    height: 250px;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: opacity 400ms ease;
    background: rgba(0, 0, 0, 0.5);
  }

  &__changing {
    opacity: 1;
    cursor: not-allowed;
  }

  &__text {
    @extend %heading;
    font-size: 2rem;
    color: $true-white;
  }

  .input__button {
    text-align: center;
  }
}
