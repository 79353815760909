.how,
.shooter-info,
.cost-info,
.info {
  background-image: radial-gradient(circle at center, #fff 0, #f5f5f5 100%);
  &__container {
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__bottom,
  &__top {
    max-width: 768px;
    padding: 3rem;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1280px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    min-width: 45%;
    max-width: 512px;
    padding: 3rem;
  }

  &__heading {
    @extend %heading;
    text-shadow: 0px 2px 6px #ffffff;
    font-size: 2.5rem;
    background-image: url('/static/img/flower.svg');
    background-repeat: no-repeat;
    background-position: 0% 57.5%;
    background-size: 2rem 2rem;
    padding-left: 3rem;

    &--shooter {
      background-image: url('/static/img/camera.svg');
    }
  }

  &__text {
    @extend %text-body;

    &--top {
      position: relative;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        bottom: -3rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 0.25rem;
        background: $brand-color;
      }
    }

    &--bottom {
      @extend %button-shadow;
      color: $black;
      border: solid 2px $black;
    }
  }
}

.cost-info {
  &__heading {
    background: none;
    padding: none;
    text-align: center;
  }

  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    text-align: center;
    &--top::after {
      background: $brand-color;
    }
  }
}

.how {
  background: $true-white;

  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__heading {
    padding: 0;
    background: none;
    font-size: 2.5rem;

    &--top {
      font-size: 2rem;
    }
  }

  &__text {
    text-align: center;

    &--free {
      position: relative;
      margin-top: 6rem;
      margin-bottom: 2rem;
      &::before {
        content: '';
        position: absolute;
        top: -3rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 0.25rem;
        background: $brand-color;
      }
    }
  }

  &__number {
    background-color: $brand-green;
    border-radius: 50%;
    display: inline-block;
    color: $true-white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    font-weight: bold;

    &--shooter {
      background-color: $brand-color;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    width: 2rem;
    height: 2rem;
  }

  &__content {
    flex-direction: column;
    align-items: center;
  }

  &__bottom {
    padding-top: 0;
  }
}

.shooter-info {
  background-color: $brand-color;
  background-image: none;
  color: $true-white;
  &__text {
    text-align: center;

    &--top {
      &::after {
        background: $true-white;
      }
    }

    &--bottom {
      @extend %button-shadow;
      color: $white;
      border: solid 2px $true-white;
      &:hover {
        color: $brand-color;
        border: solid 2px $true-white;
        background: $true-white;
      }
    }
  }

  &__heading {
    text-shadow: none;
    text-align: center;
    padding: 0;
    background-image: none;
  }
}

.feedback {
  background-color: $brand-color;
  color: $true-white;

  &__container {
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__top {
    position: relative;
    margin-bottom: 3rem;
    &::after {
      content: '';
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 2rem;
      height: 0.25rem;
      background: $true-white;
    }
  }
  &__img {
    width: 2rem;
    height: 2rem;
  }

  &__heading {
    @extend %heading;
    text-shadow: 0px 2px 6px #ffffff;
    font-size: 2.5rem;
    text-shadow: none;
    text-align: center;
    padding: 0;
    background-image: none;
  }

  &__text {
    margin: 0 auto;
    margin-top: 3rem;
    @extend %text-body;
    margin-bottom: 1rem;
    align-self: center;
    justify-self: center;
    text-align: center;
    width: 512px;
    max-width: 80vw;
  }

  &__author {
    @extend %text-body;
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 3rem;
  }

  &__shooters {
    @extend %button-shadow-invert;
    max-width: 384px;
    margin: 5rem 0 3rem;
  }

  .carousel .slide {
    background: transparent;
  }

  .slider-wrapper {
    max-width: 100vw;
    overflow: hidden;
  }
}
